import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/common/layout/Layout"
import SubscribeForm from "../components/sections/SubscribeForm"
import Seo from "../components/common/SEO"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  // maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
// const codeStyles = {
//   color: "#8A6534",
//   padding: 4,
//   backgroundColor: "#FFF4DB",
//   fontSize: "1.25rem",
//   borderRadius: 4,
// }

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Page non trouvée" />
      <div style={pageStyles}>
        <title>Page non trouvée</title>
        <h1 style={headingStyles}>Oups page non trouvée</h1>
        <p style={paragraphStyles}>
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          Nous n'avons pas trouvé cette page
          <br />
          <Link to="/">Accueil</Link>.
        </p>
      </div>
      <SubscribeForm />
    </Layout>
  )
}

export default NotFoundPage
